.info-dash {
  height: 15vh;
  box-shadow: 0 0 4px rgba(110, 110, 110, 0.781);
  border-radius: 10px;
  min-width: 150px;
  margin: 10px;
  background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);
  font-size: 20px;
  color: #4b48a3;
}

.info-dash:hover,
.info-dash-expense:hover,
.info-dash-encaisse:hover {
  transform: scale(1.05);
  transition: all 0.3s;
}

.info-dash-expense {
  height: 15vh;
  box-shadow: 0 0 4px rgba(110, 110, 110, 0.781);
  border-radius: 10px;
  min-width: 150px;
  margin: 10px;
  background-image: linear-gradient(to top, #f3dfe0 0%, white 100%);
  font-size: 20px;
  color: #893030;
}

.info-dash-encaisse {
  height: 15vh;
  box-shadow: 0 0 4px rgba(110, 110, 110, 0.781);
  border-radius: 10px;
  min-width: 150px;
  margin: 10px;
  background-image: linear-gradient(to top, #d0edcf 0%, white 100%);
  font-size: 20px;
  color: #448229;
}
