@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

* {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
  color: inherit;
}

a:hover {
  color: #fff;
}

.green {
  background-color: #f6ffed;
  color: #237804;
}

.red {
  background-color: #fff1f0;
  color: #a8071a;
}

.backbtn {
  display: flex;
  margin-bottom: 40px;
  padding: 8px;
  border: #237804 solid 1px;
  border-radius: 10px;
  color: #237804;
  width: 80px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 10px;
}

.backbtn:hover {
  background-color: #237804;
  color: white;
}
