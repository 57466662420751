.item {
  justify-content: center;
  box-shadow: 0 0 4px rgba(110, 110, 110, 0.781);
  padding: 15px;
  margin-top: 20px;
}
.item:hover {
  transform: scale(1.05);
  transition: all 0.3s;
}
.item .name {
  text-align: center;
  color: tomato;
  font-size: 20px;
}
.item .price {
  margin-top: 0.5rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.699);
  font-size: 20px;
}

.item .quantity {
  text-align: center;
  color: rgba(0, 0, 0, 0.699);
  font-size: 20px;
}

.item button {
  margin-top: 10px;
  background-color: rgb(15, 155, 15) !important;
  color: white !important;
}

input {
  border: 1px solid rgb(0, 39, 31) !important;
  box-shadow: none !important;
  outline: none !important;
}
input:focus {
  box-shadow: none !important;
  outline: none !important;
}
.ant-select-selector {
  border: 1px solid rgb(0, 39, 31) !important;
  box-shadow: none !important;
  outline: none !important;
}

.category {
  border: 2px solid rgb(179, 176, 176);
  margin-right: 50px;
  align-items: center;
  padding-left: 25px;
  border-radius: 10px;
  cursor: pointer;
}
.category h4 {
  margin-right: 25px;
  font-size: 22px;
}
.category img {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: -3px;
}
.selected-category {
  border: 3px solid rgb(3, 38, 66);
}

.subtotal h3 {
  color: rgb(0, 133, 18) !important;
  font-size: 35px;
  padding: 15px;
}

/* ================================================Bill styling */

.bill-header {
  border-bottom: 2px dotted rgb(77, 76, 76);
}
.bill-model h1 {
  font-size: 30px;
}
.bill-model p {
  margin-top: 0;
  margin-bottom: 0 !important;
}
.dotted-border {
  margin: 5px 0;
  border-bottom: 2px dotted rgb(77, 76, 76);
}
.bill-model h2 {
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 600px) {
  .categories {
    width: 100%;
    overflow-x: scroll;
  }
}
